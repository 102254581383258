[data-page='news-events'],
[data-page='career']{
  overflow: hidden;

  @include background-image('bg-news-events.jpg', cover, center);
  background-attachment: fixed;

  .wrapper--inner{
    position: relative;
    padding: 0;
    margin-top: 140px;
    margin-bottom: 5%;

    & > .tab--section{
      & > .tab-list{
        transition: 400ms $easeOutQuart;
      }

      .tab--nav{
        padding: 0 7%;
      }

      .tab{
        width: 100%;
      }
    }
  }

  .video--gallery{
    $per-row: 2;
    display: flex;
    flex-wrap: wrap;

    padding: 0 7%;

    h1{
      @include responsive-header();
      max-width: 100%;
      flex: 0 0 100%;
    }

    .video{
      flex: 0 0 100%/$per-row;
      max-width: 100%/$per-row;

      padding: 10px;
    }

    .video--box{
      position: relative;
      overflow: hidden;
      padding-top: 56.25%;

      background: black;

      iframe, .video--thumbnail{
        position: absolute;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;
        border: 0;
      }
    }

    .video--thumbnail{
      background-size: cover;
      background-position: center;

      cursor: pointer;

      &:before{
        content: '';
        position: absolute;
        z-index: 1;
        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        background: linear-gradient(to bottom, rgba(black, 0.9) 0, transparent 30%, transparent 70%, rgba(black, 0.9) 100%),
                    linear-gradient(to bottom, black 0, black 15%, transparent 15%, transparent 85%, black 85%);
                  ;

        pointer-events: none;
      }

      svg{
        position: absolute;
        z-index: 2;
        top: 50%;
        left: 50%;

        transform: translate(-50%, -50%);
        width: 80px;

        &, *{ transition: all 400ms $easeOutQuart; }

        circle{ stroke: white; }
        path{ fill: white; }
      }

      &:hover{
        svg{
          transform: translate(-50%, -50%) scale(1.1);

          circle{ stroke: $color-supernova; }
          path{ fill: $color-supernova; }
        }
      }
    }

    .video--title{
      position: relative;
      z-index: 2;

      font-size: 1.2rem;
      line-height: 1;

      margin-left: 10px;
      margin-top: 2.5%;

      color: white;
    }
  }
}

@include breakpoint(max, xs){
  [data-page='news-events'],
  [data-page='career']{
    .wrapper--inner{
      margin-top: 100px;
      margin-bottom: 100px;
    }

    .video--gallery{
      $per-row: 1;

      .video{
        flex: 0 0 100%/$per-row;
        max-width: 100%/$per-row;

        padding: 0;
        margin: 5% auto;
      }

      .video--title{
        line-height: 1;
        font-size: 1rem;
      }
    }
  }
}
