.overlay--video{
  position: fixed;
  z-index: 999;

  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  pointer-events: none;
}

.video--player{
  video{
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 50%;

    min-width: 100%;
    min-height: 100%;

    opacity: 0;

    transform: translate(-50%, -50%);
  }
}

.video--logo{
  svg{
    position: absolute;
    z-index: 9;
    top: 50%;
    left: 50%;

    width: 100%;
    height: auto;

    min-width: 1280px;

    opacity: 0;

    transform: translate(-50%, -50%);
  }
}

.video--controls{
  position: relative;
  z-index: 11;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;

  width: 100%;
  height: 100%;

  padding: 3%;

  & > *{ pointer-events: all; }
}

.play-button{
  display: none;
}

.video--curtains{
  $closed-offset: 69%;
  div{
    position: absolute;
    top: 0;

    width: 120%;
    height: 100%;

    background: #f7f7f7;
    transform: skewX(35deg);
  }

  div:first-child{ left: -$closed-offset; }

  div:last-child{ right: -$closed-offset; }
}

@include breakpoint(max, xs){
  .video--player{
    video{
      min-width: initial;
      min-height: initial;

      width: 170vw;
    }
  }

  .video--logo{
    svg{
      width: 170vw !important;
      min-width: initial;
    }
  }

  .video--controls{
    flex-direction: column;
    align-items: center;

    padding-bottom: 5vh;
  }

  .play-button{
    border: none;
    outline: none !important;
    background: none;

    display: initial;
    position: absolute;
    z-index: 11;
    top: 50%;
    left: 50%;

    transform: translate(-50%, -50%);

    pointer-events: all;

    svg{
      width: 50%;
    }
  }
}
