[data-page='collection']{
  overflow: hidden;

  @include background-image('bg-collection.jpg', cover, center);
  background-attachment: fixed;

  .wrapper--inner{
    position: relative;
    padding: 0;
    margin-top: 140px;
    margin-bottom: 7%;
  }
}

@include breakpoint(max, xs){
  [data-page='collection']{
    .wrapper--inner{
      margin-top: 100px;
      margin-bottom: 100px;
    }
  }
}
