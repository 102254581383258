/* Bootstrap */
@import 'bootstrap-reboot';
@import 'bootstrap-grid';
@import '../../node_modules/bootstrap/scss/modal';
// @import 'utilities/flex';

/* PhotoSwipe */
@import 'photoswipe/src/css/main-settings';
@import 'photoswipe/src/css/main';
@import 'photoswipe/src/css/default-skin/default-skin';

/* Import Base */
@import 'base/fonts';
@import 'base/mixins';
@import 'base/variables';
@import 'base/typography';
@import 'base/utilities';

/* Import Components */
@import 'components/button';

@import 'components/header';
@import 'components/footer';

@import 'components/floating-box';
@import 'components/sub-navigation';

@import 'components/home-carousel';
@import 'components/collection-carousel';
@import 'components/news-carousel';

@import 'components/overlay-video';

@import 'components/modal';

/* Import Templates */
@import 'templates/base';
@import 'templates/legal';
@import 'templates/forms';

/* Import Pages */
@import 'pages/home';
@import 'pages/about-us';
@import 'pages/collection';
@import 'pages/news-events';
@import 'pages/contact-us';
@import 'pages/registration';
