$active-transition: top 200ms $easeInQuart,
                    transform 350ms 230ms $easeOutQuart,
                    background-color 430ms $easeOutQuart;

$inactive-transition: transform 200ms $easeInQuart,
                      top 350ms 230ms $easeOutQuart,
                      background-color 430ms $easeOutQuart;

header{
  position: fixed;
  z-index: 100;
  top: 3%;
  left: 0;

  width: 100%;
  padding: 0 5%;

  pointer-events: none;
}

.header--logo{
  float: left;

  width: 200px;

  margin: 10px 0;

  transition: opacity 200ms linear;

  pointer-events: all;

  img{
    max-width: 100%;
    height: auto;
  }

  &.is-hidden{
    opacity: 0;
    pointer-events: none;
  }
}

// Hamburger Icon
.hamburger{
  float: right;

  position: relative;
  z-index: 10;
  float: right;

  margin: 15px 0;

  width: 32px;
  height: 18px;

  cursor: pointer;
  pointer-events: all;

  span{
    display: block;

    position: absolute;
    top: 50%;
    left: 0;

    width: 100%;
    height: 3px;
    border-radius: 10px;

    background: black;

    transform: translateY(-50%);
    transform-origin: center;

    transition: all 400ms $easeOutQuart;
  }

  span:first-child{
    top: 0;
    transition: $inactive-transition;
  }

  span:last-child{
    top: 100%;

    transition: $inactive-transition;
  }
}

.hamburger.is-active{
  span{
    background: $color-supernova;
  }

  span:first-child{
    top: 50%;
    transform: translateY(-50%) rotate(45deg);

    transition: $active-transition;
  }

  span:nth-child(2){
    opacity: 0;
  }

  span:last-child{
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);

    transition: $active-transition;
  }
}

// Navigation Menu
.navigation{
  position: fixed;
  z-index: 9;
  top: 0;
  right: 0;

  width: 50vw;
  height: 100%;

  pointer-events: none;

  svg{
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    pointer-events: none;
  }

  &:before{
    content: '';
    position: fixed;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    background: black;
    opacity: 0.5;

    transition: all 550ms $easeInOutQuart;
  }

  &:not(.is-active){
    .navigation--links{ pointer-events: none; }
    &:before{ opacity: 0; }
  }
}

.navigation--links{
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;

  width: 100%;
  height: 100%;

  padding-top: 15vh;
  padding-bottom: 10vh;
  padding-right: 7vw;

  pointer-events: all;

  transition: all 550ms $easeInOutQuart;

  ul{
    text-align: right;

    padding: 0;
    list-style: none;

    li{
      display: block;
      transform: translateX(100%);
      opacity: 0;
    }

    a{
      text-decoration: none;

      // @include font($font-bebas, 2.3rem);
      font-family: $font-bebas;
      color: white;

      @include vw-fontsize(2.7vw, $h1-font-size, $h1-font-size*1.5);

      &:hover{
        color: $color-supernova;
      }
    }
  }

  .sm--icons{
    display: flex;
    justify-content: center;
    align-items: center;

    li{
      margin: 0 10px;
    }

    a:hover{
      path{ fill: $color-supernova; }
    }

    svg{
      position: static;
      width: 25px;
      height: 25px;

      path{
        fill: white;
        transition: fill 450ms $easeOutQuart;
      }
    }
  }
}

@include breakpoint(max, xs){
  header{
    top: 15px;
  }

  .header--logo {
    width: 120px;
  }

  .navigation{
    width: 100vw;
  }

  .navigation--links{
    align-items: center;
    padding: 15vh 0;
    padding-bottom: 5vh;

    ul{
      text-align: center;
    }
  }
}
