// font mixin
@mixin font($family, $size: unset, $weight: unset, $line-height: unset, $color: unset){
  font-family: $family;
  @if $size != unset { font-size: $size; }
  @if $weight != unset { font-weight: $weight; }

  @if $color != unset { color: $color; }
  @if $line-height != unset { line-height: $line-height; }
}

// background image mixin
@mixin background-image($url, $size: unset, $position: unset ){
  background: url('./../img/#{$url}');

  @if $size != unset{ background-size: $size; }
  @if $position != unset{ background-position: $position; }
}

@mixin pseudo-background-image($url, $size: unset, $position: unset ){
  position: relative;

  &:before{
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    @include background-image($url, $size, $position);
    @content;
  }
}

// media breakpoint mixin
@mixin breakpoint($m, $breakpoint){
  $breakpoints: (
  'xl': $screen-breakpoint-xl,
  'lg': $screen-breakpoint-lg,
  'md': $screen-breakpoint-md,
  'sm': $screen-breakpoint-sm,
  'xs': $screen-breakpoint-xs
  );

  @media all and (#{$m}-width: map-get($breakpoints, $breakpoint)){
    @content;
  }
}

// vw font-size with min / max
@mixin vw-fontsize($vw-size, $min, $max){
  font-size: $vw-size;

  @include breakpoint(max, sm){
    font-size: $min;
  }

  @media all and (min-width: 1920px){
    font-size: $max;
  }
}

@mixin responsive-header(){
  @include vw-fontsize(5vw, $h1-font-size*1.2, $h1-font-size*2);
}
