[data-page='registration']{
  @include background-image('bg-registration.jpg', cover, center);
  background-attachment: fixed;

  .section--title{
    h1{
      @include responsive-header();
    }
  }
}
