@mixin slideImage($size){
  $thumb-width: 500px;
  $thumb-height: 630px;

  height: $size;
  width: $thumb-width / $thumb-height * $size;

  max-width: $thumb-width;
  max-height: $thumb-height;
}

.collection--carousel{
  position: relative;
  transition: 450ms $easeInOutQuart;

  .carousel--header{
    display: flex;
    justify-content: space-between;

    width: 100%;

    padding: 0 7%;
    margin-bottom: 3%;

    h1{
      @include responsive-header();
    }
  }

  .carousel--track{
    display: flex;
    flex-wrap: nowrap;
    margin: 0 7%;

    user-select: none;

    cursor: grab;

    &:active{
      cursor: grabbing;

      .slide--thumb{
        cursor: grabbing;
      }
    }
  }

  .scroll--prompt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    flex: 0 0 200px;
    padding-right: 20px;

    transition: opacity 400ms $easeOutQuart;

    p{
      margin-bottom: 10px;
      padding-left: 20px;

      text-align: right;
      font-size: 0.9rem;
      font-style: italic;
    }

    &.is-hidden{
      opacity: 0;
    }
  }

  .indicators{
    display: flex;
    align-items: center;

    span{
      margin: 0 5px;
      font-size: 1.5rem;
    }

    .indicator--icons{
      $size: 40px;

      flex: 0 0 $size;
      max-width: $size;

      background-repeat: no-repeat;
      width: $size;
      height: $size;

      &.grabbable{
        animation: grabbing 1.4s $easeInOutQuart both infinite;

        @keyframes grabbing{
          0%{
            transform: none;
            @include background-image('icon-grab.png', contain, center);
          }
          30%{
            transform: scale(0.8);
            @include background-image('icon-grabbing.png', contain, center);
          }
          40%{
            transform: scale(0.8);
            @include background-image('icon-grabbing.png', contain, center);
          }
          70%{
            transform: scale(0.8) translateX(-25px);
            @include background-image('icon-grabbing.png', contain, center);
          }
          100%{
            transform: none;
            @include background-image('icon-grab.png', contain, center);
          }
        }
      }

      &.scroll{
        position: relative;
        @include background-image('icon-scroll-mouse.png', contain, center);

        .wheel{
          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;

          @include background-image('icon-scroll-wheel.png', contain, center);
          animation: scrolling 600ms $easeInOutQuart both infinite alternate;

          @keyframes scrolling{
            0%{ transform: none; }
            100%{ transform: translateY(7px); }
          }
        }
      }
    }
  }

  .scroll--prompt,
  .modal--back{
    button{
      background: none;
      outline: none;
      border: none;

      width: 100%;

      text-align: right;
      cursor: pointer;

      svg{
        width: 100%;
      }

      &, *{ transition: transform 400ms $easeOutQuart; }

      // states
      &:hover{
        transform: translateX(-5px);

        svg{ transform: translateX(-5px); }
      }
    }
  }

  // Carousel Thumb
  .thumb--list{
    position: relative;
    white-space: nowrap;
  }

  .slide--thumb{
    display: inline-block;
    user-select: none;

    margin: 0 3vw;
    cursor: pointer;

    *{ margin: 0; }

    .slide--image{
      @include slideImage(18vw);

      position: relative;
      margin-bottom: 7px;
      overflow: hidden;

      transition: transform 400ms $easeOutQuart;
      pointer-events: none;

      &.is-logo img {
        width: 100%;
        height: auto;
        max-height: 50%;
      }

      img{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);

        height: 100%;

        transition: filter 400ms $easeOutQuart;
        filter: saturate(0);
      }
    }

    .slide--title{
      *:first-child{
        transition: transform 500ms $easeOutQuart;
        transition-delay: 60ms;
      }

      *:last-child{
        transition: transform 500ms $easeOutQuart;
      }
    }

    // states
    &:hover{
      .slide--image{
        transform: scale(1.05);
      }

      .slide--title{
        *:first-child{
          transform: translateY(3vh);
        }

        *:last-child{
          transform: translateY(3vh);
        }
      }

      img{ filter: saturate(1); }
    }
  }

  // Popup Modal
  .carousel--modal{
    width: 100%;
    height: 100%;
  }

  .modal--inner{
    padding: 0 7%;
  }

  .tab-list{
    transition: all 400ms $easeOutQuart;
  }

  .tab--nav{
    display: flex;
    justify-content: flex-end;

    padding-bottom: 3%;
  }

  .modal--back.is-arrow{
    max-width: 13%;
    flex: 0 0 13%;

    margin-right: 4%;

    padding-top: 15%;
    transition: all 400ms $easeOutQuart;
  }

  .block--logo{
    padding: 0 20px;
    margin-bottom: 30px;

    img{
      max-width: 400px;
      height: auto;
    }
  }

  .block--description{
    img{
      max-width: 100%;
      height: auto;
    }
  }

  .modal--image{
    padding-left: 4%;

    .image--box{
      width: 125%;
      overflow: hidden;
    }

    img{
      width: 100%;
      height: auto;
    }
  }

  .image--gallery{
    column-count: 4;
    column-gap: 0;

    &.location-map > *{
      column-count: unset;
    }

    p{
      column-span: all;
    }

    figure{
      margin: 0 5px;
      margin-bottom: 10px;
    }

    figcaption{
      display: none;
    }

    img{
      width: 100%;
      height: auto;
    }
  }
}

// other elements
.sub--navigation.is-hidden{
  opacity: 0;
}

// Transition Animations
.slide--thumb:not(.is-animating){
  transition: all 600ms $easeInOutQuart;
}

@include breakpoint(max, xs){
  .collection--carousel{
    .carousel--header{
      flex-direction: column;
    }

    .thumb--list{
      width: 100%;
      white-space: normal;
    }

    .slide--thumb{
      left: 0;
      width: 100%;

      margin: 0;
      padding: 15px 0;

      .slide--image{
        position: static;

        width: 100%;
        height: 200px;

        max-width: initial;
        max-height: initial;

        img{
          position: static;
          top: initial;
          left: initial;
          transform: none;

          width: 100%;
          height: 100%;
        }
      }
    }

    .modal--back{
      margin-bottom: 40px;
    }

    .block--logo{
      max-width: 80%;
      margin: 0 auto;
      margin-bottom: 40px;
    }

    .modal--image{
      padding: 0;

      .image--box{
        width: 100%;
      }
    }

    .image--gallery{
      column-count: 2;
    }
  }
}
