[data-page='home']{
  .wrapper--inner{
    padding: 0;
  }
}

[data-page='home'] .floating--content .content--wrapper{
  &:before{
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    @include background-image('bg-floating-news.jpg', cover, center);

    opacity: 0.5;
  }

  h1{
    // font-size: 3.6rem;
    @include vw-fontsize(3.8vw, $h1-font-size, $h1-font-size*1.5);
    margin-bottom: 8%;
  }

  .news--container{
    display: block;
  }

  .news--container a{
    color: black;
    text-decoration: none;
  }

  .news--snippet{
    text-align: right;
    margin-bottom: 5%;

    opacity: 0.8;
    transition: opacity 550ms $easeOutQuart;

    p{ font-size: 0.85rem; }

    &:hover{
      opacity: 1;
    }
  }
}

@include breakpoint(min, xs){
  [data-page="home"] {
    [data-section-index="0"] {
      color: white;
    }

    [data-section-index="1"] {
      color: white;
    }

    [data-section-index="2"] {
      color: white;
    }

    [data-section-index="3"] {
      color: white;

      .slide--header span{
        background: #884525 !important;
      }    

      .button span {
        color: white;
      }

      .button svg polygon:nth-child(2) {
        stroke: #884525 !important;
      }

      .button svg polygon:nth-child(3) {
        fill: #884525 !important;
      }
    }
    
    [data-section-index="3"]{
      color: white;

      .slide--header span{
        background: #d5de2c !important;
      }    

      .button span {
        color: #131e30;
      }

      .button svg polygon:nth-child(2) {
        stroke: #d5de2c !important;
      }

      .button svg polygon:nth-child(3) {
        fill: #d5de2c !important;
      }
    }

    // [data-section-index="4"] {
    //   color: white;
    // }

    .for-desktop{
      display: block;
    }

    .for-mobile{
      display: none;
    }

    // [data-section-index="4"]{
    //   color: white;

    //   .slide--header span{
    //     background: #d5de2c !important;
    //   }    

    //   .button span {
    //     color: #131e30;
    //   }

    //   .button svg polygon:nth-child(2) {
    //     stroke: #d5de2c !important;
    //   }

    //   .button svg polygon:nth-child(3) {
    //     fill: #d5de2c !important;
    //   }
    // }
  }
}

@include breakpoint(max, xs){
  [data-page='home'] {
    [data-section-index="0"],
    [data-section-index="1"],
    [data-section-index="2"],
    [data-section-index="5"],
    [data-section-index="6"] {
      color: white;
    }

    
    [data-section-index="7"] {
      color: black;
    }

    [data-section-index="3"]
    // [data-section-index="4"]
    {
      color: white;

      .slide--background{
        background-position: 70% 50% !important;
      }
    }

    .for-desktop{
      display: none;
    }

    .for-mobile{
      display: block;
    }

    .floating--box{
    position: relative;
    top: initial;
    left: initial;

    .floating--boxes{ display: none; }

    .floating--content{
      position: relative;
      top: initial;
      right: initial;

      width: 100%;
      height: auto;

      max-width: initial;
      max-height: initial;

      .content--wrapper{
        position: relative;

        padding: 40px 15px;
        padding-bottom: 100px;

        &:before{
          z-index: initial;
        }
      }

      .content--inner{
        position: relative;
        width: 100%;
        max-width: initial;
      }

      .news--snippet{
        padding: 0;
        text-align: left;
      }
    }
  }
  }
}
