body{
  overflow-y: scroll;
  overflow-x: hidden;
}

#wrapper{
  min-height: 100vh;

  opacity: 0;
  transition: all 800ms $easeInOutQuart;
  backface-visibility: hidden;
}

.wrapper--inner{
  padding-top: 140px;
  padding-bottom: 7%;
}

.section{
  padding: 0 7%;
}

.section--inner{
  position: relative;
}

@include breakpoint(max, xs){
  .wrapper--inner{
    padding-top: 100px;
    padding-bottom: 100px;
  }
}

ul{
  padding-left: 20px;
}

.position-absolute {
  position: absolute;
}

.w-100 {
  width: 100%;
}

.top-0 {
  top: 0;
}