@font-face {
    font-family: 'Bebas Neue';
    src: url('./../fonts/bebasneue_regular.woff2') format('woff2'),
         url('./../fonts/bebasneue_regular.woff') format('woff'),
         url('./../fonts/bebasneue_regular.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Kollektif';
    src: url('./../fonts/kollektif.woff2') format('woff2'),
         url('./../fonts/kollektif.woff') format('woff'),
         url('./../fonts/kollektif.ttf') format('ttf');
    font-weight: normal;
    font-style: normal;
}
