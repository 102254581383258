form{
  *{
    color: #333333;
  }

  p{
    font-size: 0.95rem;
  }
}

.input--group, .checkbox--group{
  margin-bottom: 30px;

  label{
    display: block;
    margin: 0;

    font-size: 0.95rem;
  }

  .danger{
    display: block;

    font-size: 0.8rem;
    color: firebrick;

    min-height: 1.2rem;
  }
}

// select and input fields
.input--group{
  input, select, textarea{
    /* Override Default Styles */
    appearance: none;
    border-radius: none;
    background: none;
    outline: none;
    border: none;

    width: 100%;
    height: 28px;

    padding: 0 7px;

    transition: color 450ms $easeOutQuart;

    /* States */

    // focused or has input
    &:focus + .border:after,
    &.has-value + .border:after{
      max-width: 100%;
      background: black;
    }

    // invalid
    &.has-error{
      color: firebrick;

      & + .border:after{
        max-width: 100%;
        background: firebrick;
      }
    }

    &:-webkit-autofill{
      animation: autofillHack;
      animation-fill-mode: both;

      @keyframes autofillHack{
        to { color: #333333; background: transparent; }
      }
    }
  }

  select{
    @include background-image('select-arrow.png', 14px, calc(100% - 14px) center);
    background-repeat: no-repeat;

    &:focus{
      @include background-image('select-arrow-reverse.png', 14px, calc(100% - 14px) center);
      background-repeat: no-repeat;
    }
  }

  .border{
    position: relative;
    width: 100%;

    &:before, &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 2px;

      transition: all 600ms $easeOutQuart;
    }

    &:before{
      background: black;
      opacity: 0.3;
    }

    &:after{
      z-index: 1;
      max-width: 0;
    }
  }
}

// checkboxes
$checkbox-size: 18px;
$checkbox-margin: 15px;

$checkbox-color-active: #333333;
$checkbox-color-inactive: #ced1d2;

$checkbox-border-thickness: 2px;

.checkbox--group{
  p{
    margin-bottom: 0;
  }

  .danger{
    margin-bottom: 10px;
  }

  .checkbox--set{
    position: relative;
    margin-bottom: 15px;
  }

  .checkbox{
    position: relative;
    margin-bottom: 10px;
  }

  input{
    position: absolute;

    top: 0;
    left: 0;

    width: $checkbox-size;
    height: $checkbox-size;

    opacity: 0;
    // pointer-events: none;
  }

  input:checked + .custom-checkbox{
    &:before{
      border-color: $checkbox-color-active;
    }

    &:after{
      background: $checkbox-color-active;
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }
  }

  .custom-checkbox{
    position: absolute;
    top: 0;
    left: 0;

    width: $checkbox-size;
    height: $checkbox-size;

    pointer-events: none;

    &:before, &:after{
      content: '';
      position: absolute;
      top: 0;
      left: 0;

      width: 100%;
      height: 100%;

      transition: all 250ms $easeOutQuart;
    }

    &:before{
      border: $checkbox-border-thickness solid $checkbox-color-inactive;
    }

    &:after{
      top: 50%;
      left: 50%;

      transform: translate(-50%, -50%) scale(0);
      transform-origin: center;
      opacity: 0;

      width: calc(100% - #{$checkbox-border-thickness*4});
      height: calc(100% - #{$checkbox-border-thickness*4});

      background: $checkbox-color-inactive;
    }
  }

  label{
    margin-left: $checkbox-size + $checkbox-margin;
    font-size: 0.95rem;
  }
}


// ==========================
// Form Notification

.modal--form{
  display: flex;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  background: rgba(black, 0.5);

  .modal--inner{
    margin: auto initial;

    width: 60%;
    background: white;
    padding: 3%;

    h1{
      font-size: 3rem;
    }
  }
}
