.button{
  $pad-top: 10px;
  $pad-side: 28px;

  display: inline-flex;
  align-items: center;

  position: relative;

  padding-top: $pad-top*1.2;
  padding-bottom: $pad-top;
  padding-left: $pad-side;
  padding-right: $pad-side*1.8;

  cursor: pointer;

  transition: all 400ms $easeOutQuart;

  button{
    border-radius: none;
    background: none;
    outline: none;
    border: none;
  }

  a, button{
    position: absolute;
    z-index: 3;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  svg{
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;

    width: 100%;
  }

  span{
    position: relative;
    z-index: 2;

    font-size: 1rem;
    line-height: 1rem;

    color: $color-supernova;
  }

  &:hover{
    transform: translateY(-2px);
  }

  &:active{
    transform: none;
  }
}

.button-cross {
  float: right;

  position: relative;
  z-index: 10;
  float: right;

  margin: 15px 0;

  width: 32px;
  height: 18px;

  cursor: pointer;
  pointer-events: all;

  span{
    display: block;

    position: absolute;
    top: 50%;
    left: 0;

    width: 100%;
    height: 3px;
    border-radius: 10px;

    background: $color-supernova;

    transform: translateY(-50%);
    transform-origin: center;

    transition: all 400ms $easeOutQuart;
  }

  &.dark-blue {
    & span {
      background: $color-darkblue;
    }
  }
  
  span:first-child{
    top: 50%;
    transform: translateY(-50%) rotate(45deg);
  }
  
  span:nth-child(2){
    opacity: 0;
  }
  
  span:last-child{
    top: 50%;
    transform: translateY(-50%) rotate(-45deg);
  }
}