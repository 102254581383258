[data-page='about-us'] {
  position: relative;

  &:before {
    content: '';

    position: absolute;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    @include background-image('bg-about-us.jpg', cover, center);
    background-attachment: fixed;
  }

  .section {
    img {
      max-width: 100%;
    }

    h1 {
      @include responsive-header();
    }
  }

  .floating--content .content--wrapper {
    @include background-image('bg-floating-about.jpg');
  }

  .awards {
    display: block;
    max-width: 500px !important;
    margin-bottom: 2rem;
  }
  
  .awards-sm {
    display: block;
    max-width: 150px !important;
    margin-bottom: 2rem;
  }
  
  .awards-xs {
    display: block;
    max-width: 80px !important;
    margin-bottom: 2rem;
  }

  .achievements {
    max-width: 550px !important;
    margin: 25px 0;
  }
}

@include breakpoint(max, xs) {
  [data-page='about-us'] {
    &:before {
      opacity: 0.3;

      background-size: initial;
      background-position: top;
    }

    .floating--box {
      display: none;
    }
  }
}
