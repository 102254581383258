.news--carousel{
  position: relative;

  .carousel--header{
    display: flex;
    justify-content: space-between;

    padding: 0 7%;
    margin-bottom: 40px;

    width: 100%;

    // h1{ font-size: 4.5vw; }
    h1{
      @include responsive-header();
    }
  }

  .carousel--track{
    display: flex;
    flex-wrap: nowrap;
    margin: 0 7%;

    user-select: none;

    cursor: grab;

    &:active{
      cursor: grabbing;

      .slide--thumb{
        cursor: grabbing;
      }
    }
  }

  .scroll--prompt{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;

    flex: 0 0 200px;
    padding-right: 20px;

    transition: opacity 400ms $easeOutQuart;

    p{
      margin-bottom: 10px;
      padding-left: 20px;

      text-align: right;
      font-size: 0.9rem;
      font-style: italic;
    }

    &.is-hidden{
      opacity: 0;
    }
  }

  .indicators{
    display: flex;
    align-items: center;

    span{
      margin: 0 5px;
      font-size: 1.5rem;
    }

    .indicator--icons{
      $size: 40px;

      flex: 0 0 $size;
      max-width: $size;

      background-repeat: no-repeat;
      width: $size;
      height: $size;

      &.grabbable{
        animation: grabbing 1.4s $easeInOutQuart both infinite;

        @keyframes grabbing{
          0%{
            transform: none;
            @include background-image('icon-grab.png', contain, center);
          }
          30%{
            transform: scale(0.8);
            @include background-image('icon-grabbing.png', contain, center);
          }
          40%{
            transform: scale(0.8);
            @include background-image('icon-grabbing.png', contain, center);
          }
          70%{
            transform: scale(0.8) translateX(-25px);
            @include background-image('icon-grabbing.png', contain, center);
          }
          100%{
            transform: none;
            @include background-image('icon-grab.png', contain, center);
          }
        }
      }

      &.scroll{
        position: relative;
        @include background-image('icon-scroll-mouse.png', contain, center);

        .wheel{
          position: absolute;
          top: 0;
          left: 0;

          width: 100%;
          height: 100%;

          @include background-image('icon-scroll-wheel.png', contain, center);
          animation: scrolling 600ms $easeInOutQuart both infinite alternate;

          @keyframes scrolling{
            0%{ transform: none; }
            100%{ transform: translateY(7px); }
          }
        }
      }
    }
  }

  .scroll--prompt,
  .modal--back{
    button{
      background: none;
      outline: none;
      border: none;

      width: 100%;

      text-align: right;
      cursor: pointer;

      svg{
        width: 100%;
      }

      &, *{ transition: transform 400ms $easeOutQuart; }

      // states
      &:hover{
        transform: translateX(-5px);

        svg{ transform: translateX(-5px); }
      }
    }
  }

  // Carousel Thumb
  .thumb--list{
    position: relative;
    white-space: nowrap;
  }

  .slide--thumb{
    display: inline-block;
    user-select: none;

    margin: 0 3vw;

    cursor: pointer;

    *{ margin: 0; }

    .slide--title{
      @include slideImage(18vw);

      position: relative;
      margin-bottom: 7px;
      padding: 15px;
      overflow: hidden;

      transition: transform 400ms $easeOutQuart;
      pointer-events: none;

      background: black;

      white-space: normal;
      color: $color-supernova;
    }

    .slide--date{
      *:first-child{
        transition: transform 500ms $easeOutQuart;
        transition-delay: 60ms;
      }

      *:last-child{
        transition: transform 500ms $easeOutQuart;
      }
    }

    // states
    &:hover{
      .slide--title{
        transform: scale(1.05);
      }

      .slide--date{
        *:first-child{
          transform: translateY(3vh);
        }

        *:last-child{
          transform: translateY(3vh);
        }
      }
    }
  }

  // Popup Modal
  .carousel--modal{
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    min-height: 70vh;

  }

  .modal--inner{
    padding: 0 7%;
  }

  .modal--back.is-arrow{
    max-width: 13%;
    flex: 0 0 13%;

    padding-top: 15%;
  }

  .modal--date{
    max-width: 5%;
    flex: 0 0 5%;

    margin: 0 3%;
    padding-top: 5px;

    span{
      display: block;
      font-family: $font-bebas;
      margin: 0;
      padding: 0;
    }

    span:nth-child(1){
      @include vw-fontsize(2.2vw, 1.5rem, 2.4rem);
      line-height: 1;
    }
    span:nth-child(2){
      @include vw-fontsize(4vw, 3rem, 4rem);
      line-height: 1;
    }
    span:nth-child(3){
      @include vw-fontsize(1.8vw, 1.2rem, 2rem);
      line-height: 1;
    }
  }

  .block--title{
    @include vw-fontsize(3.4vw, $h1-font-size*1.2, $h1-font-size*1.7);
    margin-bottom: 25px;
  }

  .block--content{
    img{
      width: 100%;
      height: auto;
    }
  }

  .image--gallery{
    column-count: 4;
    column-gap: 0;

    figure{
      margin: 0 5px;
      margin-bottom: 10px;

      padding: 0;
    }

    figcaption{
      display: none;
    }

    img{
      width: 100%;
      height: auto;
    }
  }
}

// other elements
.sub--navigation.is-hidden{
  opacity: 0;
}

// Transition Animations
.slide--thumb:not(.is-animating){
  transition: all 600ms $easeInOutQuart;
}

@include breakpoint(max, xs){
  .news--carousel{
    .carousel--header{
      flex-direction: column;
    }

    .thumb--list{
      width: 100%;
      white-space: normal;
    }

    .slide--thumb{
      left: 0;
      width: 100%;

      margin: 0;
      padding: 15px 0;

      .slide--title{
        position: static;

        width: 100%;
        height: auto;

        max-width: initial;
        max-height: initial;
      }
    }

    .carousel--modal{
      .modal--inner > .row{
        flex-direction: column;
      }

      .modal--back{
        margin-bottom: 40px;
      }

      .modal--date{
        flex: initial;
        width: 100%;

        max-width: initial;
        margin: 10px auto;

        span{
          display: inline-block;
          font-size: 1.5rem !important;
        }
      }
    }

    .modal--content{
      flex: 0 0 100%;
      max-width: 100%;
    }

    .image--gallery{
      column-count: 2;
    }
  }
}
