[data-page='contact-us']{
  @include background-image('bg-contact-us.jpg', cover, center);
  background-attachment: fixed;

  .section--title{
    h1{
      @include responsive-header();
    }
  }

  .icon--group{
    display: flex;

    // address
    &:nth-child(2){ margin-bottom: 20px; }
    // 3rd number
    &:nth-child(5){ margin-bottom: 20px; }

    .icon{
      flex: 0 0 45px;
      max-width: 45px;
      height: 24px;

      img{
        height: 100%;
        width: auto;
      }
    }

    p{
      margin-top: 3px;
      margin-bottom: 0;

      a{
        color: black;
      }
    }
  }
}
