.floating--box{
  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  height: 100%;

  pointer-events: none;
  display: none; // hide for now
}

.floating--boxes{
  position: absolute;
  z-index: 1;
  max-width: 280px;
  max-height: 330px;

  width: 16vw;
  height: 18vw;

  background: $color-supernova;
  opacity: 0.4;

  &:first-child{
    top: 0;
    right: 0;
  }

  &:last-child{
    top: 54vh;
    right: 28vw;
  }
}

.floating--content{
  position: absolute;
  z-index: 2;
  top: 14vh;
  right: 9%;

  width: 30vw;
  height: 70vh;

  max-width: 500px;
  max-height: 600px;

  pointer-events: all;

  overflow: hidden;

  .content--wrapper{
    width: 100%;
    height: 100%;

    padding: 30px;
    background: $color-supernova;
  }

  .content--inner{
    width: calc(30vw - 30px*2);
    height: 100%;

    max-width: calc(500px - 30px*2);
    overflow: hidden;
  }

  .content{
    width: calc(100% + 25px);

    padding-right: 25px;
    height: 100%;
    overflow: auto;
  }
}

// animation start state
@include breakpoint(min, xs){
  .floating--content .content--wrapper{
    transform: translateX(50%);
    opacity: 0;
  }

  .floating--boxes{
    width: 0;
    opacity: 0.4;
  }
}
