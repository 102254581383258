[data-page='privacy-policy'],
[data-page='disclaimer']{
  position: relative;
  
  @include background-image('bg-news-events.jpg', cover, center);
  background-attachment: fixed;

  &:after{
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    right: 15%;

    background: $color-supernova;
    opacity: 0.7;
    width: 340px;
    height: 100%;
  }

  h1{
    font-size: 3rem;
  }

  p + h3{
    margin-top: 50px;
  }
}

@include breakpoint(max, xs){
  [data-page='privacy-policy'],
  [data-page='disclaimer']{
    &:after{
      content: none;
    }
  }
}
