.modal {
    .modal-header {
        justify-content: flex-end;
        border-bottom: 0;
    }

    img {
        max-width: 100%;
    }

    &.fade:not(.show) {
        opacity: 0;
    }

    &.fade {
        transition: opacity .15s linear;
    }
}