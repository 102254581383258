.sub--navigation{
  opacity: 0;

  ul{
    position: relative;
    display: flex;
    flex-wrap: wrap;

    list-style: none;
    padding: 0;
  }

  li{
    position: relative;
    z-index: 2;
    padding: 0 20px;

    @include font($font-bebas, 1.5rem);
    color: black;

    cursor: pointer;
    overflow: hidden;

    transition: all 350ms $easeOutQuart;

    // states
    &:hover{
      transform: translateY(-2px);
    }

    &.is-current{
      color: $color-supernova;
    }
  }

  svg{
    position: absolute;
    z-index: 1;

    top: 0;
    left: 0;

    width: calc(100% + 5px);

    pointer-events: none;
  }
}

@include breakpoint(max, xs){
  .sub--navigation{
    li{
      padding: 0 10px;
    }
  }
}
