// root font size
:root{
  font-size: 16px;
}

@include breakpoint(max, lg){
  :root{
    font-size: 14px;
  }
}

@include breakpoint(max, xs){
  :root{
    font-size: 15px;
  }
}

// default font family
*{
  @include font($font-kollektif, unset, unset);
}

h1, h2, h3, h4, h5, h6 {
  @include font($font-bebas, unset, normal, initial);
}

// generate font sizes
$font-sizes: (
  'h1': $font-size-h1,
  'h2': $font-size-h2,
  'h3': $font-size-h3,
  'h4': $font-size-h4,
  'h5': $font-size-h5,
  'h6': $font-size-h6,
  'p' : $font-size-p
);

@each $selector, $size in $font-sizes{
  #{$selector}{
    font-size: $size;
  }
}
