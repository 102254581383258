.carousel--wrapper {
  position: relative;
}

.carousel--wrapper .carousel {
  height: 100vh;
  width: 100vw;

  &>div>div {
    position: relative;
  }

  .slide {
    padding-left: 7%;

    &>.row {
      display: flex;
      align-items: center;

      width: 100%;
      height: 100vh;
    }

    h1 {
      @include vw-fontsize(5.5vw, $h1-font-size*1.2, $h1-font-size*2);
    }
  }

  img {
    display: block;
    max-width: 200px;

    margin: 20px 0;
  }

  .slide--background {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  // [data-section-index='0'] .slide--background{
  //   @include background-image('landing-hero-cny2021.jpg', cover, center);
  // }

  // [data-section-index='0'] .slide--background{
  //   @include background-image('landing-hero-cny2022.jpg', cover, center);
  // }

  // [data-section-index='0'] .slide--background {
  //   @include background-image('landing-hero-cny2023.jpg', cover, center);

  //   @include media-breakpoint-down(md) {
  //     @include background-image('landing-hero-cny2023-m.jpg', cover, center);
  //   }
  // }

  // [data-section-index='0'] {
  //   &:before  {
  //     content: unset !important;
  //   }
  //   & .slide--background {
  //     @include background-image('landing-hero-cny2024.jpg', cover, center);

  //     @include media-breakpoint-down(md) {
  //       @include background-image('landing-hero-cny2024-m.jpg', cover, center);
  //     }
  //   }
  //   // & .col-sm-4 {
  //   //   @include media-breakpoint-up(md) {
  //   //     margin-bottom: auto;
  //   //     margin-top: 15%;
  //   //   }
  //   // }
  // }

  [data-section-index='0'] {
    & .slide--background {
      @include background-image('binastra-cny-2025.jpg', cover, center);
    }
  }

  [data-section-index='1'] {
    & .slide--background {
      @include background-image('binastra-web-banner.jpg', cover, center);
    }
    // & .col-sm-4 {
    //   @include media-breakpoint-up(md) {
    //     margin-bottom: auto;
    //     margin-top: 15%;
    //   }
    // }
  }
  
  [data-section-index='2'] {
    & .slide--background {
      @include background-image('trion-bg.jpg', cover, center);
    }
    & .col-sm-4 {
      @include media-breakpoint-up(md) {
        margin-bottom: auto;
        margin-top: 15%;
      }
    }
  }

  // [data-section-index='1'] .slide--background {
  //   @include background-image('landing-mercure-hotel.png', cover, center);
  // }

  // [data-section-index='0'] {
  //   &:before {
  //     content: unset !important;
  //   }
  // }

  // [data-section-index='2'] .slide--background {
  //   @include background-image('landing-hero-trion-retails.jpg', cover, center);
  // }

  // [data-section-index='2'] .slide--background {
  //   @include background-image('landing-hero-mercure.jpg', cover, center);
  // }

  // [data-section-index='4'] .slide--background {
  //   @include background-image('landing-hero-trion2.jpg', cover, center);
  // }

  // [data-section-index='4'] .slide--background {
  //   @include background-image('landing-hero-trion.jpg', cover, center);
  // }

  [data-section-index='3'] .slide--background {
    @include background-image('binastra-retails-bg.jpg', cover, center);
  }
  
  [data-section-index='4'] .slide--background {
    @include background-image('binastra-BBP2-bg.jpg', cover, center);
  }

  [data-section-index='5'] .slide--background {
    @include background-image('binastra-BBP1-bg.jpg', cover, center);
  }

  [data-section-index='6'] .slide--background {
    @include background-image('landing-hero-suria-garden.jpg', cover, center);
  }

  [data-section-index='7'] .slide--background {
    @include background-image('landing-hero-spiral.jpg', cover, center);
  }

  [data-section-index='8'] .slide--background {
    @include background-image('landing-hero-hand.jpg', cover, center);
  }

  [data-section-index='9'] .slide--background {
    @include background-image('landing-hero-spiral.jpg', cover, center);
  }

  // for cny 2022 only
  // [data-section-index='0'] {
  //   padding-left: 0;

  //   .row {
  //     justify-content: center;
  //   }

  //   .col-sm-4 {
  //     padding-left: 3rem;
  //     padding-right: 3rem;
  //   }

  //   .slide--header {
  //     align-items: center;
  //   }

  //   .slide--header span:first-child{
  //     background: #F8E7AA;
  //   }

  //   .content__image {
  //     max-width: 100%;
  //   }
  // }
}

.carousel--wrapper .slide--header {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  &>* {
    position: relative;
    margin-bottom: 10px;
  }

  span:first-child {
    position: absolute;
    top: 0;
    left: 0;

    width: 0;
    height: 100%;
    background: black;

    transform-origin: right;
  }

  span {
    font: inherit;
    line-height: inherit;
  }
}

.carousel--wrapper .slide--content {
  span {
    display: inline-block;
    line-height: $font-size-p;
    margin-right: 1.8px;
  }

  a {
    color: inherit;
  }

  p>span {
    overflow: hidden;
  }

  .button {
    margin: 0 10px 10px 0;
  }
}

.carousel--pagination {
  $clickarea: 8px;
  $thickness: 4px;

  display: flex;

  position: absolute;
  bottom: 5%;
  left: 50%;

  transform: translateX(-50%);

  &>div {
    position: relative;
    width: calc(1/10 * 80vw);
    max-width: 150px;
    height: $thickness;

    margin: 0 4px;
    padding: $clickarea 0;

    transition: all 400ms $easeOutQuart;
    cursor: pointer;

    &:before,
    &>div {
      position: absolute;
      top: 50%;
      left: 0;

      transform: translateY(-50%);
      transform-origin: right;

      height: $thickness;
    }

    // black background
    &:before {
      content: '';
      display: block;
      width: 100%;

      background: black;

      transition: all 400ms $easeOutQuart;
    }

    // yellow progress bar
    &>div {
      background: $color-supernova;
    }

    // is-current state
    &.is-current div {
      width: 100%;
    }

    // hover state
    &:hover {
      transform: translateY(-2px);

      &:before {
        box-shadow: 0 2px 6px rgba(0, 0, 0, 0.6);
      }
    }
  }
}

@include breakpoint(max, xs) {
  .carousel--wrapper .carousel {
    height: 90vh;

    .slide {
      position: relative;

      display: flex;
      justify-content: center;
      align-items: center;

      min-height: 90vh;

      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;

        background: black;
        opacity: 0.4;
        // background: white;
        width: 100%;
        height: 100%;
        // opacity: 0.6;
      }
    }

    // for cny2022 only
    // [data-section-index='0']:before {
    //   background-color: transparent;
    // }
  }

  .carousel--pagination {
    &>div {
      width: 15vw;
    }

    // for more than 6 slides
    &>div {
      width: 8vw;
    }
  }
}