body{ position: relative; }

footer{
  display: flex;
  justify-content: space-between;

  position: absolute;
  z-index: 100;
  left: 0;
  bottom: 15px;

  width: 100%;
  padding: 0 7%;

  font-size: 0.9rem;

  p{
    font-size: inherit;
    margin: 0;
  }

  & > ul{
    display: flex;

    padding: 0;
    margin: 0;
    list-style: none;

    li{
      margin: 0 10px;
      cursor: pointer;

      &:hover{
        text-decoration: underline;
      }
    }
  }

  .modal--legal{
    display: flex;

    position: fixed;
    z-index: 1000;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    padding: 3% 0;
    overflow: auto;

    background: rgba(black, 0.5);

    .modal--inner{
      position: relative;
      margin: auto;

      width: 60%;
      height: auto;

      background: white;
      padding: 3%;
    }

    h1{
      font-size: 3rem;
    }

    p + h3{
      margin-top: 50px;
    }
  }

  .modal--close{
    position: absolute;
    top: 8px;
    right: 8px;

    width: 28px;
    height: 28px;

    transition: transform 400ms $easeOutQuart;
    cursor: pointer;

    &:before, &:after{
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;

      width: 28px;
      height: 3px;

      background: black;
    }

    &:before{ transform: translate(-50%, -50%) rotate(-45deg); }
    &:after{ transform: translate(-50%, -50%) rotate(45deg); }

    // States
    &:hover{
      transform: scale(1.1);
    }
  }
}

@include breakpoint(max, xs){
  footer{
    flex-wrap: wrap;

    p{
      margin-bottom: 10px;
    }

    .modal--legal{
      .modal--inner{
        width: 90%;
      }

      ul{
        padding-left: 20px;
      }
    }
  }
}
